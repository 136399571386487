import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { SelectorComponent } from './selector/selector.component';
import { MultipleSelectorComponent } from './multiple-selector/multiple-selector.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { TileSelectorComponent } from './tile-selector/tile-selector.component';
import { ChipSelectorComponent } from './chip-selector/chip-selector.component';
import { MultipleTileSelectorComponent } from './multiple-tile-selector/multiple-tile-selector.component';
import { InputNumberComponent } from './input-number/input-number.component';
import { InputTextComponent } from './input-text/input-text.component';
import { ManageDocumentComponent } from './manage-document/manage-document.component';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { CalculResultComponent } from './calcul-result/calcul-result.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HomeComponent } from './home/home.component';
import { HomeSlideComponent } from './home-slide/home-slide.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { FormContactComponent } from './form-contact/form-contact.component';
import { AccountComponent } from './account/account.component';
import { CreateAccountComponent } from './create-account/create-account.component';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LegalNoticeComponent } from './legal-notice/legal-notice.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { CandidateGuideComponent } from './candidate-guide/candidate-guide.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FooterComponent } from './footer/footer.component';
import { CguComponent } from './cgu/cgu.component';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { TranslateModule } from '@ngx-translate/core';

registerLocaleData(localeFr, 'fr');

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: 'asgoodaspay.com' 
  },
  palette: {
    popup: {
      background: '#454545'
    },
    button: {
      background: '#000000'
    }
  },
  theme: 'edgeless',
  type: 'opt-out'
};


@NgModule({
  declarations: [
    AppComponent,
    SelectorComponent,
    MultipleSelectorComponent,
    TileSelectorComponent,
    ChipSelectorComponent,
    MultipleTileSelectorComponent,
    InputNumberComponent,
    InputTextComponent,
    CalculResultComponent,
    HomeComponent,
    HomeSlideComponent,
    SignUpComponent,
    FormContactComponent,
    AccountComponent,
    CreateAccountComponent,
    ContactUsComponent,
    ManageDocumentComponent,
    LegalNoticeComponent,
    AboutUsComponent,
    CandidateGuideComponent,
    ForgotPasswordComponent,
    FooterComponent,
    CguComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    AgmCoreModule.forRoot({
      apiKey: ' AIzaSyAY-rrM850JSilXwm1Aw84EBU6WLG78vAI ',
      libraries: ['places']
    }),
    MatGoogleMapsAutocompleteModule,
    MatSelectInfiniteScrollModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    TranslateModule.forRoot()
  ],
  providers: [],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
