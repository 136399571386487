import { Injectable } from '@angular/core';
import { Account } from './account.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public account : Account;
  public displayMenu : boolean = true;
  public whiteMenu : boolean = true;

  constructor() { 
  }

  ngOnInit() {
  }  

  public setAccount(account: Account) {
    this.account = account;
    console.log('Account: ', account);
    localStorage.setItem('account', JSON.stringify(this.account));
  }
}
