import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-multiple-selector',
  templateUrl: './multiple-selector.component.html',
  styleUrls: ['./multiple-selector.component.scss']
})
export class MultipleSelectorComponent<T> implements OnInit {

  @Input('placeholder') placeholder: String;
  @Input('label') label: String;
  @Input('width') width: number;
  @Input('widthPct') widthPct: number;
  @Input('required') required: boolean;
  @Output() onValueChange = new EventEmitter<T[]>();
  selectedValues: T[];

  constructor() { }

  ngOnInit() {
  }

  _entries: EntrySelector<T>[];

  @Input()
  get entries() {
    return this._entries;
  }

  set entries(_entries: EntrySelector<T>[]) {
    this._entries = _entries;
    if(this._entries.length>5) {
      document.documentElement.style.setProperty('--small-offset-multiple', '-200px');
      document.documentElement.style.setProperty('--large-offset-multiple', '248px');
    } else {
      document.documentElement.style.setProperty('--small-offset-multiple', '0px');
      document.documentElement.style.setProperty('--large-offset-multiple', '0px');
    }
  }

  @Input()
  get values() {
    return this.selectedValues;
  }

  set values(vals: T[]) {
    let selectedValues: T[] = [];

    for(let i=0; i<vals.length; i++) {
      let found: Boolean = false;
      for(let j=0; j<this.entries.length; j++) {
        if(vals[i]['name'] == this.entries[j].name) {
          selectedValues.push(this.entries[j].value);
          found=true;
        }
      }
      if(!found) {
        selectedValues.push(vals[i]);
      }
    }

    let updated: Boolean = false;
    if(JSON.stringify(this.selectedValues) != JSON.stringify(selectedValues)) {
      updated = true;
    }
    this.selectedValues = selectedValues;
    if(updated) {
      this.onValueChange.emit(this.selectedValues);
    }
  }

  compareFn(c1: T, c2: T): boolean {
    return JSON.stringify(c1) == JSON.stringify(c2);
  }
}

export class EntrySelector<T> {  
  name: String;
  value: T;
  tooltip: String;

  constructor(value: T, name: String, tooltip: String = null) { 
    this.value = value;
    this.name = name;
    this.tooltip = tooltip;
  }
}
