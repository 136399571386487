import { Adapter } from "./adapter";
import { Injectable } from "@angular/core";

export class Job {
    id: number;
    name: String;
    icon: String;
}

@Injectable({
    providedIn: "root"
})
export class JobAdapter implements Adapter<Job> {
    adapt(res: any): Job {
        const job : Job = new Job();
        job.id = res.id;
        job.name = res.name;
        job.icon = res.icon;
        return job;
    }
}