import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tile-selector',
  templateUrl: './tile-selector.component.html',
  styleUrls: ['./tile-selector.component.scss']
})
export class TileSelectorComponent<T> implements OnInit {

  @Input('entries') entries: EntryTileSelector<T>[];
  @Input('cols') cols: number;
  @Output() valueChange = new EventEmitter<T>();
  @Output() onValueChange = new EventEmitter<T>();
  selectedValue: T;

  constructor() { }

  ngOnInit() {
  }

  @Input()
  get value() {
    return this.selectedValue;
  }

  set value(val: T) {
    this.selectedValue = val;
    this.valueChange.emit(this.selectedValue);
    this.onValueChange.emit(this.selectedValue);
  }

  compare(obj1, obj2) {
    if(obj1==undefined) return false;
    if(obj2==undefined) return false;

    return JSON.stringify(obj1)==JSON.stringify(obj2);
/*
    //Loop through properties in object 1
    for (var p in obj1) {
      //Check property exists on both objects
      if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;
   
      switch (typeof (obj1[p])) {
        //Deep compare objects
        case 'object':
          if (!this.compare(obj1[p], obj2[p])) return false;
          break;
        //Compare function code
        case 'function':
          if (typeof (obj2[p]) == 'undefined' || (p != 'compare' && obj1[p].toString() != obj2[p].toString())) return false;
          break;
        //Compare values
        default:
          if (obj1[p] != obj2[p]) return false;
      }
    }
    */
   /*
    //Check object 2 for any extra properties
    for (var p in obj2) {
      if (typeof (obj1[p]) == 'undefined') { console.log('ok5: ' +p); return false; }
    }
    */
  }
}

export class EntryTileSelector<T> {  
  name: String;
  icon: String;
  value: T;

  constructor(value: T, icon: String, name: String) { 
    this.value = value;
    this.icon = icon;
    this.name = name;
  }
}
