import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../api.service';
import { Account } from '../account.model';
import { Document } from '../document.model';
import { Candidate } from '../candidate.model';

@Component({
  selector: 'app-manage-document',
  templateUrl: './manage-document.component.html',
  styleUrls: ['./manage-document.component.scss']
})
export class ManageDocumentComponent implements OnInit {

    _account: Account;

    @Input('account')
    set account(account: Account) {
      this._account = account;
      this.refreshDocuments();
    }
    get account() {
      return this._account;
    }

    documents: Document[];

    constructor(private apiService: ApiService) {

    }

    ngOnInit() {
    }

    refreshDocuments() {
      if (!this.account) { return; }
      this.apiService.getDocuments(this.account).subscribe((documents) => {
        console.log('documents: ', documents);
        this.documents = documents;
      });
    }

    async uploadDocument(event) {
      const selectedFile = event.target.files[0];
      this.apiService.addDocument(this.account, selectedFile).subscribe(() => {
        console.log('addDocument');
        this.refreshDocuments();
      });
    }

    async deleteDocument(document) {
      this.apiService.deleteDocument(this.account, document.id).subscribe(() => {
        console.log('deleteDocument');
        this.refreshDocuments();
      });
    }

    async downloadDocument(document) {
      this.apiService.getDocumentContent(this.account, document.id).subscribe((data) => {
        this.downloadFile(data, document.name);
      });
    }

    downloadFile(content, filename) {
      const file = filename.substring(1, filename.length - 1);
      const extension = file.split('.')[1].toLowerCase();
      // It is necessary to create a new blob object with mime-type explicitly set otherwise only Chrome works like it should
      const newBlob = new Blob([content], { type: this.createFileType(extension) });

      // IE doesn't allow using a blob object directly as link href instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers: Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      console.log(data);
      const link = document.createElement('a');
      link.href = data;
      link.download = filename;
      link.click();
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 400);
    }

    createFileType(e): string {
      let fileType = '';
      if (e === 'pdf' || e === 'csv') {
        fileType = `application/${e}`;
      } else if (e === 'jpeg' || e === 'jpg' || e === 'png') {
        fileType = `image/${e}`;
      } else if (e === 'txt') {
        fileType = 'text/plain';
      } else if (e === 'ppt' || e === 'pot' || e === 'pps' || e === 'ppa') {
        fileType = 'application/vnd.ms-powerpoint';
      } else if (e === 'pptx') {
        fileType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
      } else if (e === 'doc' || e === 'dot') {
        fileType = 'application/msword';
      } else if (e === 'docx') {
        fileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      } else if (e === 'xls' || e === 'xlt' || e === 'xla') {
        fileType = 'application/vnd.ms-excel';
      } else if (e === 'xlsx') {
        fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      }
      return fileType;
    }
  }