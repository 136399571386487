import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { ContactUs } from '../contact-us.model';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
 
    contactUs: ContactUs

    formContactUs: FormGroup; 

    messageSent: boolean

    constructor(private apiService: ApiService) { 
      this.contactUs = new ContactUs();

      this.formContactUs = new FormGroup({
        email: new FormControl('',[Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
        phone: new FormControl('',[Validators.pattern("^[0-9\(\)\+]+$")]),
        body: new FormControl('',[Validators.required]),
      });  
    }
        
    ngOnInit() {
    }

    async sendMessage(): Promise<void> {
      await this.apiService.contactUs(this.contactUs).subscribe((res)=>{
        this.messageSent = true;
      });
    }
  }
  