import { Component, OnInit, } from '@angular/core';
import { ApiService } from '../api.service';
import { Job } from '../job.model';
import { RangeEntry } from '../range-entry.model';
import { JobSpeciality } from '../job-speciality.model';
import { Technology } from '../technology.model';
import { Candidate } from '../candidate.model';
import { CandidateLocation } from '../candidate-location.model';
import { CandidateJobSpeciality } from '../candidate-job-speciality.model';
import { SalaryPeriod } from '../salary-period.enum';
import { SalaryType } from '../salary-type.enum';
import { TimePeriod } from '../time-period.enum';
import { AcademicLevelType } from '../academic-level-type.enum';
import { LanguageLevel } from '../language-level.enum';
import { CandidateLevel } from '../candidate-level.enum';
import { EntryTileSelector } from '../tile-selector/tile-selector.component';
import { EntrySelector } from '../selector/selector.component';
import { Location } from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
import { ParameterType } from '../parameter-type.enum';
import { CandidateParameter } from '../candidate-parameter.model';
import { JobTechnology } from '../job-technology.model';
import { JobRemote } from '../job-remote.enum';
import { slideInAnimation } from '../route-animation';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  animations: [ slideInAnimation ]
})
export class SignUpComponent implements OnInit {
    title = 'projet-rh';
    jobs: Job[];
    entriesJob: EntryTileSelector<Job>[];
    jobSpecialities: JobSpeciality[];
    technologies: Technology[];
    jobTechnologies: JobTechnology[];
    entriesAcademicLevel: EntrySelector<AcademicLevelType>[];
    entriesCandidateJobSpeciality: EntryTileSelector<CandidateJobSpeciality>[];
    entriesCandidateTechnology: EntryTileSelector<Technology>[];
    entriesNumberEmployeesAverageExperience: EntrySelector<RangeEntry>[];
    numberEmployeesAverageExperience: RangeEntry;
    entriesCompanySector: EntrySelector<String>[];
    entriesCandidateLevel: EntrySelector<CandidateLevel>[];
    entriesEnglishLevel: EntrySelector<LanguageLevel>[];
    entriesJobRemote: EntrySelector<JobRemote>[];
    entriesSalaryPeriod: EntrySelector<SalaryPeriod>[];
    entriesSalaryType: EntrySelector<SalaryType>[];
    entriesTimePeriod: EntrySelector<TimePeriod>[];
    entriesLocations: EntrySelector<CandidateLocation>[];
    entriesIngSysOs: EntrySelector<String>[];
    entriesProjectManagmentUnit: EntrySelector<String>[];

    candidate: Candidate;

    companySector: String;
    companySectorCustom: String='';
    step: number;
    stepTotal: number=5;
    loading: Boolean;
    stepComplete: Boolean;

    progressPercentage: number;
  
    valueLongestMission: number;
    timePeriodLongestMission: TimePeriod = TimePeriod.MONTH;

    experienceValue: number;
    experiencePeriod: TimePeriod = TimePeriod.YEAR;
    
    experienceJobValue: number;
    experienceJobPeriod: TimePeriod = TimePeriod.YEAR;

    public ParameterType = ParameterType; 
    candidateTechnologies: Technology[] = [];
/*
    backUrl: boolean = false;
    startUrl: String;
    endUrl: String;
*/
    constructor(private apiService: ApiService, private router: Router, private route: ActivatedRoute) {
      this.step = 1;
      //this.loadTechnologies();
      /*
      router.events.subscribe(event => {
        if (event instanceof NavigationStart) {        
          this.startUrl = event.url;
          console.log('start:', event.url);
        };
        if (event instanceof NavigationEnd) {     
          this.endUrl = event.url;       
          console.log('end:', event.url);
        };
        if(this.startUrl!="" && this.startUrl == this.endUrl) {
          this.backUrl = true;
        } else {
          this.backUrl = false;
        }
        console.log('backUrl:', this.backUrl, 'start:', this.startUrl, 'end:', this.endUrl);
      });
*/
      this.route = route;
            
      this.entriesAcademicLevel = new Array<EntrySelector<AcademicLevelType>>();
      this.entriesAcademicLevel.push(new EntrySelector<AcademicLevelType>(AcademicLevelType.NIVEAU_BAC, "NIVEAU_BAC", "Niveau Bac"));
      this.entriesAcademicLevel.push(new EntrySelector<AcademicLevelType>(AcademicLevelType.DUT_BTS_BAC2, "DUT_BTS_BAC2", "DUT, BTS, Bac+2"));
      this.entriesAcademicLevel.push(new EntrySelector<AcademicLevelType>(AcademicLevelType.LICENCE_BAC3, "LICENCE_BAC3", "Licence, BAC+3"));
      this.entriesAcademicLevel.push(new EntrySelector<AcademicLevelType>(AcademicLevelType.MASTER1_IEP_IUP_BAC4, "MASTER1_IEP_IUP_BAC4", "Master 1, IEP, IUP, Bac+4"));
      this.entriesAcademicLevel.push(new EntrySelector<AcademicLevelType>(AcademicLevelType.MASTER2_DESS_DEA_BAC5, "MASTER2_DESS_DEA_BAC5", "Master 2, DESS, DEA, Bac+5"));
      this.entriesAcademicLevel.push(new EntrySelector<AcademicLevelType>(AcademicLevelType.ECOLE_INGENIEUR, "ECOLE_INGENIEUR", "Ecole d’ingénieurs"));
      this.entriesAcademicLevel.push(new EntrySelector<AcademicLevelType>(AcademicLevelType.DOCTORAT_CYCLE3, "DOCTORAT_CYCLE3", "Doctorat, 3ème cycle"));
      this.entriesAcademicLevel.push(new EntrySelector<AcademicLevelType>(AcademicLevelType.AUTRES, "AUTRES", "Autres"));

      this.entriesNumberEmployeesAverageExperience = new Array<EntrySelector<RangeEntry>>();
      this.entriesNumberEmployeesAverageExperience.push(new EntrySelector<RangeEntry>(new RangeEntry(null, 10), null, "Moins de 10"));
      this.entriesNumberEmployeesAverageExperience.push(new EntrySelector<RangeEntry>(new RangeEntry(10, 100), null, "Entre 10 et 100"));
      this.entriesNumberEmployeesAverageExperience.push(new EntrySelector<RangeEntry>(new RangeEntry(100, 1000), null, "Entre 100 et 1000"));
      this.entriesNumberEmployeesAverageExperience.push(new EntrySelector<RangeEntry>(new RangeEntry(1000, 10000), null, "Entre 1000 et 10000"));
      this.entriesNumberEmployeesAverageExperience.push(new EntrySelector<RangeEntry>(new RangeEntry(10000, null), null, "+ de 10000"));

      this.entriesCompanySector = new Array<EntrySelector<String>>();
      this.entriesCompanySector.push(new EntrySelector<String>("Banque / Finance / Assurance", null, "Banque / Finance / Assurance", null,));
      this.entriesCompanySector.push(new EntrySelector<String>("Telecom", null, "Telecom", null,));
      this.entriesCompanySector.push(new EntrySelector<String>("Médias", null, "Médias", null,));
      this.entriesCompanySector.push(new EntrySelector<String>("Industrie", null, "Industrie", null,));
      this.entriesCompanySector.push(new EntrySelector<String>("", null, "Autres", null,));
      
      this.entriesCandidateLevel = new Array<EntrySelector<CandidateLevel>>();
      this.entriesCandidateLevel.push(new EntrySelector<CandidateLevel>(CandidateLevel.EXPERT, "EXPERT", "Excellent", ""));
      this.entriesCandidateLevel.push(new EntrySelector<CandidateLevel>(CandidateLevel.VERY_GOOD, "VERY_GOOD", "Bon", ""));
      this.entriesCandidateLevel.push(new EntrySelector<CandidateLevel>(CandidateLevel.AVERAGE, "AVERAGE", "Moyen", ""));
      
      this.entriesEnglishLevel = new Array<EntrySelector<LanguageLevel>>();
      this.entriesEnglishLevel.push(new EntrySelector<LanguageLevel>(LanguageLevel.BILINGUAL, "BILINGUAL", "Bilingue", "Je parle aussi bien anglais que français"));
      this.entriesEnglishLevel.push(new EntrySelector<LanguageLevel>(LanguageLevel.FLUENT, "FLUENT", "Courant", "Je suis capable d’animer des réunions en anglais"));
      this.entriesEnglishLevel.push(new EntrySelector<LanguageLevel>(LanguageLevel.OPERATIONAL, "OPERATIONAL", "Opérationnel", "Je comprends et suis en capacité de m’exprimer à l’oral avec un anglophone"));
      this.entriesEnglishLevel.push(new EntrySelector<LanguageLevel>(LanguageLevel.TECHNICAL, "TECHNICAL", "Technique", "Google translate est mon ami"));
      this.entriesEnglishLevel.push(new EntrySelector<LanguageLevel>(LanguageLevel.NONE, "NONE", "Aucun", "L’anglais ? Connais pas !"));
      
      this.entriesJobRemote = new Array<EntrySelector<JobRemote>>();
      this.entriesJobRemote.push(new EntrySelector<JobRemote>(JobRemote.FULL, "FULL", "100% télétravail", ""));
      this.entriesJobRemote.push(new EntrySelector<JobRemote>(JobRemote.PARTIAL, "PARTIAL", "télétravail partiel", ""));
      this.entriesJobRemote.push(new EntrySelector<JobRemote>(JobRemote.NONE, "NONE", "présentiel uniquement", ""));
   
      this.entriesSalaryPeriod = new Array<EntrySelector<SalaryPeriod>>();
      this.entriesSalaryPeriod.push(new EntrySelector<SalaryPeriod>(SalaryPeriod.MONTHLY, "MONTHLY", "mensuel"));
      this.entriesSalaryPeriod.push(new EntrySelector<SalaryPeriod>(SalaryPeriod.ANNUAL, "ANNUAL", "annuel"));

      this.entriesSalaryType = new Array<EntrySelector<SalaryType>>();
      this.entriesSalaryType.push(new EntrySelector<SalaryType>(SalaryType.GROSS, "GROSS", "brut"));
      this.entriesSalaryType.push(new EntrySelector<SalaryType>(SalaryType.NET, "NET", "net"));

      this.entriesTimePeriod = new Array<EntrySelector<TimePeriod>>();
      this.entriesTimePeriod.push(new EntrySelector<TimePeriod>(TimePeriod.MONTH, "MONTH", "mois"));
      this.entriesTimePeriod.push(new EntrySelector<TimePeriod>(TimePeriod.YEAR, "YEAR", "an(s)"));
      
      this.entriesLocations = new Array<EntrySelector<CandidateLocation>>();
      this.entriesLocations.push(new EntrySelector<CandidateLocation>(new CandidateLocation("Île de France",48.8588377, 2.2770207), null, "Île de France"));
      this.entriesLocations.push(new EntrySelector<CandidateLocation>(new CandidateLocation("Nantes",47.2382007, -1.6300954), null, "Nantes"));
      this.entriesLocations.push(new EntrySelector<CandidateLocation>(new CandidateLocation("Bordeaux",44.8637226, -0.6211603), null, "Bordeaux"));
      this.entriesLocations.push(new EntrySelector<CandidateLocation>(new CandidateLocation("PACA",44.0489444, 4.8536373), null, "PACA"));
      this.entriesLocations.push(new EntrySelector<CandidateLocation>(new CandidateLocation("Lyon",45.7579502, 4.8001017), null, "Lyon"));
      this.entriesLocations.push(new EntrySelector<CandidateLocation>(new CandidateLocation("Strasbourg",48.5690923, 7.6920548), null, "Strasbourg"));
      this.entriesLocations.push(new EntrySelector<CandidateLocation>(new CandidateLocation("Lille",50.6310623, 3.0121412), null, "Lille"));
      this.entriesLocations.push(new EntrySelector<CandidateLocation>(new CandidateLocation("Rennes",48.1159102,-1.7234738), null, "Rennes"));
      this.entriesLocations.push(new EntrySelector<CandidateLocation>(new CandidateLocation("Toulouse",43.6006786, 1.3628016), null, "Toulouse"));
      this.entriesLocations.push(new EntrySelector<CandidateLocation>(new CandidateLocation("Montpellier",43.6100166, 3.8391422), null, "Montpellier"));
      this.entriesLocations.push(new EntrySelector<CandidateLocation>(new CandidateLocation("Niort",46.3273712, -0.4963253), null, "Niort"));
      this.entriesLocations.push(new EntrySelector<CandidateLocation>(new CandidateLocation("Autres",null, null), null, "Autres"));

      this.entriesIngSysOs = new Array<EntrySelector<String>>();
      this.entriesIngSysOs.push(new EntrySelector<String>("Unix", null, "Unix"));
      this.entriesIngSysOs.push(new EntrySelector<String>("Linux", null, "Linux"));
      this.entriesIngSysOs.push(new EntrySelector<String>("Windows", null, "Windows"));
      this.entriesIngSysOs.push(new EntrySelector<String>("Mainframe", null, "Mainframe"));

      this.entriesProjectManagmentUnit = new Array<EntrySelector<String>>();
      this.entriesProjectManagmentUnit.push(new EntrySelector<String>("€", null, "€"));
      this.entriesProjectManagmentUnit.push(new EntrySelector<String>("jours/homme", null, "jours/h"));
      
      if(localStorage.hasOwnProperty('candidate')) {
        this.candidate = JSON.parse(localStorage.getItem('candidate'));

        if(this.candidate.month_longest_mission!=null) {
          if(this.candidate.month_longest_mission%12==0) {
            this.valueLongestMission = this.candidate.month_longest_mission/12;
            this.timePeriodLongestMission = TimePeriod.YEAR;
          } else {            
            this.valueLongestMission = this.candidate.month_longest_mission;
            this.timePeriodLongestMission = TimePeriod.MONTH;
          }
        }

        if(this.candidate.month_experience!=null) {
          if(this.candidate.month_experience%12==0) {
            this.experienceValue = this.candidate.month_experience/12;
            this.experiencePeriod = TimePeriod.YEAR;
          } else {            
            this.experienceValue = this.candidate.month_experience;
            this.experiencePeriod = TimePeriod.MONTH;
          }
        }
        
        if(this.candidate.month_experience_job!=null) {
          if(this.candidate.month_experience_job%12==0) {
            this.experienceJobValue = this.candidate.month_experience_job/12;
            this.experienceJobPeriod = TimePeriod.YEAR;
          } else {            
            this.experienceJobValue = this.candidate.month_experience_job;
            this.experienceJobPeriod = TimePeriod.MONTH;
          }
        }

        if(this.candidate.number_employees_min_average_experience!=null || this.candidate.number_employees_max_average_experience!=null) {
          this.numberEmployeesAverageExperience = new RangeEntry(this.candidate.number_employees_min_average_experience, this.candidate.number_employees_max_average_experience);
        }

        if(this.candidate.company_sector!=null) {
          let find=false;
          for(let i=0;i<this.entriesCompanySector.length;i++) {
            if(this.entriesCompanySector[i].value == this.candidate.company_sector) {
              find=true;
            }
          }
          if(find) {
            this.companySector = this.candidate.company_sector
          } else {            
            this.companySectorCustom = this.candidate.company_sector
          }          
        }
      } else {
        this.candidate = new Candidate();
      }
      
      if(this.candidate.candidate_job_specialities==null) this.candidate.candidate_job_specialities = [];
      if(this.candidate.locations==null) this.candidate.locations = [];


     
      //console.log('localStorage');
   //   console.log(this.candidate);
    }

    ngOnInit() {

      this.route.params.subscribe( params => {
          if (params.step !== undefined) { this.step = JSON.parse(params.step); }
          if (params.mode !== undefined && params.mode == 'new') {
            this.razSignup();
            this.router.navigate(['/signup']); return;
          }
        }
      );

      this.onStep();
      
      this.candidate.salary_type = SalaryType.GROSS;
      this.candidate.salary_period = SalaryPeriod.ANNUAL;

      this.apiService.getJobs().subscribe((data)=>{
        this.jobs = data;     
        
        const entriesJob = new Array<EntryTileSelector<Job>>();
        this.jobs.forEach(function (job: Job) {
          entriesJob.push(new EntryTileSelector<Job>(job, job.icon, job.name));
        });
        this.entriesJob = entriesJob;        
      });

      this.refreshStepComplete();
  /*
      this.apiService.getJobSpecialities(1).subscribe((data)=>{ 
      });
      */   
    }

    onFormError() {

    }

    onFormValid() {

    }

    razSignup() {
      localStorage.removeItem('candidate');
      console.log('raz signup');
      this.candidate = new Candidate();
      this.candidate.candidate_job_specialities = [];
      this.candidate.locations = [];
    }

    getCandidateJobSpeciality() : CandidateJobSpeciality {
      if(this.candidate.candidate_job_specialities.length==0) return null;
      return this.candidate.candidate_job_specialities[0];
    }

    setCandidateJobSpeciality(jobSpeciality: CandidateJobSpeciality) {
      this.candidate.candidate_job_specialities = [];
      if(jobSpeciality!=null) {
        this.candidate.candidate_job_specialities.push(jobSpeciality);
      }
    }

    public getNumberEmployeesAverageExperience() : RangeEntry {
      return this.numberEmployeesAverageExperience;
    }
  
    public setLocations(locations) {
      this.candidate.locations = locations;
      this.refreshStepComplete();
    }

    public setNumberEmployeesAverageExperience() {
      if(this.numberEmployeesAverageExperience==undefined) return;
      this.candidate.number_employees_min_average_experience=this.numberEmployeesAverageExperience.min;
      this.candidate.number_employees_max_average_experience=this.numberEmployeesAverageExperience.max;
      this.refreshStepComplete();
    }
  
    public setCompanySector() {
      if(this.companySector!="") {
        this.candidate.company_sector = this.companySector;
      } else {
        this.candidate.company_sector = this.companySectorCustom;
      }
      this.refreshStepComplete();
    }
  /*
    public onAutocompleteSelected(result: PlaceResult) {
      this.candidate.city = result.formatted_address;
    }
   
    public onLocationSelected(location: Location) {      
      this.candidate.longitude = location.longitude;
      this.candidate.latitude = location.latitude;
    }
  */
 /*
    public onLocationSelected(locations: CandidateLocation[]) {  
      this.candidate.locations = locations;
    }
    */

    lastJobSpecialityLoaded: Job;

/*
    public getJobSpecialities() {
      console.log('getJobSpecialities');
      console.log('this.candidate.job: ' + this.candidate.job);
      if(this.entriesCandidateJobSpeciality==null || this.lastJobSpecialityLoaded!=this.candidate.job) {
        this.entriesCandidateJobSpeciality=[];
        this.loadJobSpecialities();
      }
      return this.entriesCandidateJobSpeciality;
    }
*/
    public onSelectCandidateJob(job: Job) {
      this.candidate.job = job;
      this.loadJobSpecialities();
    }

    public loadJobSpecialities() {
      if(!this.candidate.job) {
        this.jobSpecialities = [];
        return;
      }
      if(this.entriesCandidateJobSpeciality!=null && this.lastJobSpecialityLoaded==this.candidate.job) {
        return;
      }
    //  this.loading = true;
      this.apiService.getJobSpecialities(this.candidate.job.id).subscribe((data)=>{
        this.jobSpecialities = data;
        
        const entriesCandidateJobSpeciality = new Array<EntryTileSelector<CandidateJobSpeciality>>();
        this.jobSpecialities.forEach(function (jobSpeciality: JobSpeciality) {
          entriesCandidateJobSpeciality.push(new EntryTileSelector<CandidateJobSpeciality>(new CandidateJobSpeciality(jobSpeciality), jobSpeciality.icon, jobSpeciality.name));
        });
        this.entriesCandidateJobSpeciality = entriesCandidateJobSpeciality;
        this.lastJobSpecialityLoaded = this.candidate.job;
      //  this.loading = false;
      });

      this.loadJobTechnologies();
    }

    private deleteParameter(parameterType: ParameterType): string {
      if(this.candidate.candidate_parameters==null) return null;
      for(let i=0;i<this.candidate.candidate_parameters.length;i++) {
        if(this.candidate.candidate_parameters[i].name == parameterType) {
          this.candidate.candidate_parameters.splice(i, 1);
        }
      }
    }
    
    // ** IngSysOs **

    public hasTechno(): Boolean {
      return this.entriesCandidateTechnology!=null && this.entriesCandidateTechnology.length>0;
    }

    public hasIngSys(): Boolean {
      if(this.candidate!=null&& this.candidate.candidate_job_specialities!=null) {        
        for(let i=0;i<this.candidate.candidate_job_specialities.length;i++) {
          if(this.candidate.candidate_job_specialities[i].job_speciality==null) continue;

          if(this.candidate.candidate_job_specialities[i].job_speciality.name=="Ingénieur systèmes") {
            return true;
          }
        }   
      }  
      this.deleteParameter(ParameterType.ING_SYS_OS);
      return false;
    }

    // ** Project managment **

    public hasProjectManagment(): Boolean {
      if(this.candidate!=null&& this.candidate.candidate_job_specialities!=null) {        
        for(let i=0;i<this.candidate.candidate_job_specialities.length;i++) {
          if(this.candidate.candidate_job_specialities[i].job_speciality==null) continue;

          if(this.candidate.candidate_job_specialities[i].job_speciality.name=="Chef de projet S.I.") return true;
          if(this.candidate.candidate_job_specialities[i].job_speciality.name=="Chef de Projet Digital") return true;
          if(this.candidate.candidate_job_specialities[i].job_speciality.name=="Directeur de projet") return true;
          if(this.candidate.candidate_job_specialities[i].job_speciality.name=="PMO") return true;
          if(this.candidate.candidate_job_specialities[i].job_speciality.name=="Gestion de projet & Coaching") return true;
        }   
      }   
      this.deleteParameter(ParameterType.PROJECT_MANAGMENT_VALUE);
      this.deleteParameter(ParameterType.PROJECT_MANAGMENT_UNIT);
      return false;
    }

    public setCandidateParameters(parameterType: ParameterType, values: string[]) {
      if(this.candidate.candidate_parameters==null) this.candidate.candidate_parameters = [];

      for(let i=0;i<this.candidate.candidate_parameters.length;i++) {
        if(this.candidate.candidate_parameters[i].name == parameterType) {
          this.candidate.candidate_parameters.splice(i, 1);
          i--;
        }
      }

      if(values==null) return;
      for(let i=0;i<values.length;i++) {
        this.candidate.candidate_parameters.push(new CandidateParameter(parameterType, values[i]));
      }
      this.refreshStepComplete();
    }

    public setCandidateParameter(parameterType: ParameterType, value: string) {
      if(value==null) return;
      let found: boolean=false;
      if(this.candidate.candidate_parameters==null) this.candidate.candidate_parameters = [];

      for(let i=0;i<this.candidate.candidate_parameters.length;i++) {
        if(this.candidate.candidate_parameters[i].name == parameterType) {
          this.candidate.candidate_parameters[i].value = value;
          found=true;
          break;
        }
      }
      if(!found) {
        this.candidate.candidate_parameters.push(new CandidateParameter(parameterType, value));
      }
    }
  
    public getCandidateParameter(parameterType: ParameterType): string {
      if(this.candidate.candidate_parameters==null) return null;
      for(let i=0;i<this.candidate.candidate_parameters.length;i++) {
        if(this.candidate.candidate_parameters[i].name == parameterType) {
          return this.candidate.candidate_parameters[i].value;
        }
      }
    }

    public refreshStepComplete() {
      setTimeout(() => {
        this.stepComplete = this.isStepComplete();
        /*
        this.paginator.page
            .pipe(
                startWith(null),
                tap(() => this.dataSource.loadLessons(...))
            ).subscribe();
            */
    });

    }

    public isStepComplete(): Boolean {
      console.log('stepComplete');
      console.log(this.candidate);
      switch(this.step) {
        case 1:
          if(!this.candidate.job) return false;
          if(!this.candidate.candidate_job_specialities) return false;
          if(this.candidate.candidate_job_specialities.length==0) return false;
          return true;
        case 2:
          return true;
        case 3:
          if(!this.candidate.month_experience && this.candidate.month_experience!==0) return false;
          if(!this.candidate.month_experience_job && this.candidate.month_experience_job!==0) return false;
          if(!this.candidate.academic_level && this.candidate.academic_level!==0) return false;
          if(!this.candidate.english_level && this.candidate.english_level!==0) return false;
          return true;
        case 4:
          if(!this.candidate.number_company_experience && this.candidate.number_company_experience!==0) return false;
          if(!this.candidate.month_longest_mission && this.candidate.month_longest_mission!==0) return false;     
          if(!this.candidate.company_sector) return false;
          if(!this.candidate.number_employees_min_average_experience && this.candidate.number_employees_min_average_experience!==0
          && !this.candidate.number_employees_max_average_experience && this.candidate.number_employees_max_average_experience!==0) return false;
          return true;
        case 5:
          if(!this.candidate.locations) return false;
          if(this.candidate.locations == undefined) return false;
          if(this.candidate.locations.length==0) return false;
          if(!this.candidate.job_remote && this.candidate.job_remote!==0) return false;
          //if(this.candidate.max_distance_wishes_to_work===undefined) return false;
          if(!this.candidate.salary) return false;
          if(!this.candidate.salary_type && this.candidate.salary_type!==0) return false;
          if(!this.candidate.salary_period && this.candidate.salary_period!==0) return false;
          return true;
      }
      return false;
    }

    public loadJobTechnologies() {
      const entriesCandidateTechnology = new Array<EntryTileSelector<Technology>>();
      
      this.apiService.getJobTechnologies(this.candidate.job.id).subscribe((jobTechnologies)=>{
        jobTechnologies.forEach(function (jobTechnology: JobTechnology) {
          entriesCandidateTechnology.push(new EntryTileSelector<Technology>(jobTechnology.technology, null, jobTechnology.technology.name));
        });
        entriesCandidateTechnology.sort(function(tech1, tech2) {
          return (tech1.name < tech2.name) ? -1 : (tech1.name > tech2.name) ? 1 : 0;
        });
        const candidateTechnologies : Technology[] = [];
        if(this.candidate.candidate_parameters!=null) {
          for(let candidateParameter of this.candidate.candidate_parameters) {
            if(candidateParameter.name!=ParameterType.JOB_TECHNOLOGY) continue;
            let id = candidateParameter.value;
            for(let entryTileSelector of entriesCandidateTechnology) {
              if(""+entryTileSelector.value.id == id) {
                candidateTechnologies.push(entryTileSelector.value);    
              }
            }    
          }
        }
        this.candidateTechnologies = candidateTechnologies;
      });

      this.entriesCandidateTechnology = entriesCandidateTechnology;
      console.log('entriesCandidateTechnology', this.entriesCandidateTechnology);
      return entriesCandidateTechnology;
    }

    public setCandidateTechnologies(technologies) {
      const values: string[] = [];
      technologies.forEach(function (technology: Technology) {
        values.push(""+technology.id);
      });
      this.setCandidateParameters(ParameterType.JOB_TECHNOLOGY , values);
    }
/*
    public setCandidateTechnologies(technologies: Technology[]) {
      console.log('setCandidateTechnologies');
      console.log(technologies);
    }
  */

    public onStep() {  
      if(this.step==1 || this.step==2) {
        this.loadJobSpecialities();
      }     

      if(this.step==3) {
        this.loadJobTechnologies();
      }     
      this.progressPercentage = ((this.step -1) / this.stepTotal)*100;
    }

    public goToNextStep() {
      //this.router.navigate(['/signup/'+(this.step)+'/'+Math.random()]); return;
      
      if(this.step==this.stepTotal) {
        console.error("Unable to go next from step " + this.step);
        return;
      }
      
      this.step++;
      if(this.step==2) {
        if(!this.hasTechno() && !this.hasProjectManagment()) {
          this.step++;
        }
      }
   //   this.onStep();

      this.localSave();
      this.router.navigate(['/signup/'+(this.step)]);  
    }
    
    public back() {
      if(this.step==1) {
        console.error("Unable to go back from step " + this.step);
        return;
      }
      this.localSave();
      this.router.navigate(['/signup/'+(this.step-1)]);  
      //this.step--;
      //this.onStep();
    }

    public localSave() {      
      localStorage.setItem('candidate', JSON.stringify(this.candidate));
    }
  
    public async save() {
      this.loading = true;
      
      await this.saveCandidate();  

      this.loading = false;
      this.router.navigate(['/result']);  
    }

    private async saveCandidate() {   
      if(this.candidate.id!=null) {
        await this.apiService.patchCandidate(this.candidate).subscribe((id)=>{
        }); 
      } else {
        await this.apiService.postCandidate(this.candidate).toPromise().then((id)=>{
          this.candidate.id = id;     
        });
      } 

      this.localSave();
    }
    
    public selectLongestMission() {
      if(this.timePeriodLongestMission==null || this.valueLongestMission==null) {
        this.candidate.month_longest_mission = null;
        this.refreshStepComplete();    
        return;
      } 

      let nbMonth;
      switch(this.timePeriodLongestMission) {
        case TimePeriod.MONTH:
          nbMonth = 1;
          break;
        case TimePeriod.YEAR:
          nbMonth = 12;
          break;
      }
      this.candidate.month_longest_mission = this.valueLongestMission * nbMonth;
      this.refreshStepComplete();    
    }

    public calculateExperience() {
      console.log('this.experienceJobValue',  this.experienceValue);
      if(this.experiencePeriod==null ||this.experienceValue==null) {
        this.candidate.month_experience = null;
        this.refreshStepComplete();    
        return;
      } 

      let nbMonth;
      switch(this.experiencePeriod) {
        case TimePeriod.MONTH:
          nbMonth = 1;
          break;
        case TimePeriod.YEAR:
          nbMonth = 12;
          break;
      }
      this.candidate.month_experience = this.experienceValue * nbMonth;
      this.refreshStepComplete();    
    }

    public calculateExperienceJob() {
      if(this.experienceJobPeriod==null || this.experienceJobValue==null) {
        this.candidate.month_experience_job = null;
        this.refreshStepComplete();    
        return;
      } 

      let nbMonth;
      switch(this.experienceJobPeriod) {
        case TimePeriod.MONTH:
          nbMonth = 1;
          break;
        case TimePeriod.YEAR:
          nbMonth = 12;
          break;
      }
      this.candidate.month_experience_job = this.experienceJobValue * nbMonth;
      this.refreshStepComplete();    
    }
  }
  