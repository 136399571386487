import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { ConfigService } from '../config.service';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    title = 'projet-rh';
     
    constructor(private apiService: ApiService, private route: ActivatedRoute, private router: Router, private configService : ConfigService) {      
      configService.displayMenu = false;
      configService.whiteMenu = false;
    }

    ngOnInit() {
      
    }
  
  }
  