import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { ConfigService } from '../config.service';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-home-slide',
  templateUrl: './home-slide.component.html',
  styleUrls: ['./home-slide.component.scss']
})
export class HomeSlideComponent implements OnInit {
    title = 'projet-rh';
     
    constructor() {      
    }

    ngOnInit() {
      
    }
  
  }
  