import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss']
})
export class InputNumberComponent implements OnInit {

  constructor() { }

  @Input('placeholder') placeholder: String;
  @Input() value: number;
  @Output() valueChange = new EventEmitter<number>();
  @Output() onValueChange = new EventEmitter<number>();
  @Input('width') width: number;
  @Input('max') max: number;
  @Input('required') required: boolean;

  error: Boolean = false;

  ngOnInit() {
  }

  onChange(value) {
    if(isNaN(Number(value))) {
      this.error = true;
      this.valueChange.emit(null);
      this.onValueChange.emit(null);
      return false;
    }
    this.error = false;
    this.value = value;
    this.valueChange.emit(value);
    this.onValueChange.emit(value);
  }
}