import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-candidate-guide',
  templateUrl: './candidate-guide.component.html',
  styleUrls: ['./candidate-guide.component.scss']
})
export class CandidateGuideComponent implements OnInit {

  constructor() {  
  }

  ngOnInit() {
  }
}
