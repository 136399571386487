import { Component, OnInit, Input} from '@angular/core';
import { Account } from '../account.model';
import { Candidate } from '../candidate.model';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {

  account: Account;
  candidate: Candidate;
  private router: Router;

  @Input('urlCallback')
  urlCallback: String = 'account';

  constructor(private _router: Router, private route: ActivatedRoute) {
    this.router = _router;
    this.route = route;
    this.account = new Account();
    this.candidate = new Candidate();
  }

  ngOnInit() {
    this.route.queryParams.subscribe( params => {
      if (params.urlCallback !== undefined) { 
        this.urlCallback = params.urlCallback;
      }       
    });
  }

  goToCallbackUrl() {
    this.router.navigate([this.urlCallback]);
  }
}