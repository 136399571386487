import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ApiService } from '../api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  step: string;
  formEmail: FormGroup;
  email: string;
  hash: string;
  id: number;
  password: string;
  error: string;

  emailValid: boolean;
  passwordValid: boolean;

  constructor(private apiService: ApiService, private route: ActivatedRoute) {
    this.route.data.subscribe(params => {
      this.step = params.step ? params.step : '';
    });

    this.route.queryParams.subscribe(params => {
      console.log(params);
      this.id = params.id;
      this.hash = params.hash;
    });
  }

  ngOnInit() {
    if (this.step === 'confirmation') {
      this.formEmail = new FormGroup({
        password: new FormControl('', [Validators.required]),
      });
    } else {
      this.formEmail = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      });
    }
  }

  public async validate() {
    await this.apiService.forgotPassword(this.email).subscribe(result => {
      this.step = 'email-sent';
      this.error='';
      console.log('success');
    },
    error => {
      this.error=error.error.message;
      console.log('error', error);
    });
  }

  public async forgotPasswordConfirmation() {
    await this.apiService.forgotPasswordConfirmation(this.id, this.hash, this.password).subscribe(
      result => {
        this.step = 'password-updated';
        this.error='';
        console.log('success');
      },
      error => {
        this.error=error.error.message;
        console.log('error', error);
      });
  }
}
