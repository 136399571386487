import { Component, OnInit, Input} from '@angular/core';
import { Location } from '@angular/common';
import { ApiService } from '../api.service';
import { ConfigService } from '../config.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Account } from '../account.model';
import { Candidate } from '../candidate.model';
import { LanguageLevel } from '../language-level.enum';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ChangeDetectorRef, AfterContentChecked} from '@angular/core';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
    title = 'projet-rh';

    email: string;
    password: string;
    simulations: Candidate[];
    account: Account;
    candidate: Candidate = new Candidate();

    formContactValidated: Boolean

    formAccount: FormGroup;

    error: string;
    loaded: boolean = false;

    @Input('urlCallback')
    urlCallback: String = null;
  
    constructor(private apiService: ApiService, private router: Router, private route: ActivatedRoute,
                private configService: ConfigService, private cdref: ChangeDetectorRef, private location: Location) {
      this.route.data.subscribe(m => {
        console.log('m', m);
        console.log('m.logout', m.logout);
        if (m.logout) {
          console.log('logout route');
          this.logout();
        }
      });

      this.route.queryParams.subscribe( params => {
        if (params.urlCallback !== undefined) { 
          this.urlCallback = params.urlCallback;
        }       
      });
      /*
      if(localStorage.hasOwnProperty('account')) {
        this.account = JSON.parse(localStorage.getItem('account'));
      } else {
        this.account = new Account();
      }
      */

      this.getMyAccount();
      this.getCandidates();

      this.formAccount = new FormGroup({
        email: new FormControl('',[Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
        password: new FormControl('',[]),
      });
    }

    async logout() {
      await this.apiService.logout();
      localStorage.removeItem('candidate');
      localStorage.removeItem('sessionId');
      this.router.navigate(['/account']);
    }

    async getCandidates() {
      await this.apiService.getCandidates().subscribe((simulations) => {
        console.log('simulations', this.simulations);
        this.simulations = simulations;
      });
    }

    async getMyAccount() {
      await this.apiService.getMyAccount().subscribe((account) => {
        this.account = account;
        this.loaded = true;
        if (!this.cdref['destroyed']) {
          this.cdref.detectChanges();
        }
      });

    }

    ngOnInit() {
    }

    getJobDescription(candidate: Candidate) : string {
      let jobDescription: string = '';
      for(let i = 0; i < candidate.candidate_job_specialities.length; i++) {
        if(i > 0) jobDescription+=' / ';
        jobDescription+=candidate.candidate_job_specialities[i].job_speciality.name;
      };
      return jobDescription;
    }

    async login() {
      const response = await this.apiService.login(this.email, this.password);
      if(response == null) {
        this.error = '';
        await this.getCandidates();
        await this.getMyAccount();
        if(this.urlCallback) {          
          console.log('urlCallback', this.urlCallback);
          this.router.navigate([this.urlCallback]);
        } else {          
          //this.location.back();
          this.router.navigate(['account']);
        }
      } else {
        switch(response) {
          case 403:
            this.error = 'Identifiant ou mot de passe invalide';
            break;
          case 500:
            this.error = 'Une erreur est survenue';
            break;
        }
      }
    }

    getLanguageLevelDescription(languageLevel: LanguageLevel) : string {
      switch(languageLevel.toString()) {
        case 'BILINGUAL':
          return 'bilingue';
        case 'FLUENT':
          return 'courant';
        case 'OPERATIONAL':
          return 'opérationel';
        case 'TECHNICAL':
          return 'technique';
        case 'NONE':
          return 'aucun';
      }

    }

    async deleteCandidate(candidate: Candidate) {
      if(confirm('Voulez-vous supprimer votre simulation ?')) {         
        this.apiService.deleteCandidate(candidate.id).subscribe(() => { 
          console.log('deleteCandidate: ' + candidate);
          const index: number = this.simulations.indexOf(candidate);
          if (index !== -1) {
              this.simulations.splice(index, 1);
          }   
        });
      }      
    }
  }