import { Adapter } from "./adapter";
import { Injectable } from "@angular/core";

export class Technology {
    id: number;
    name: String;
}

@Injectable({
    providedIn: "root"
})
export class TechnologyAdapter implements Adapter<Technology> {
    adapt(res: any): Technology {
        const technology : Technology = new Technology();
        technology.id = res.id;
        technology.name = res.name;
        return technology;
    }
}