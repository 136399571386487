import { Adapter } from "./adapter";
import { Injectable } from "@angular/core";

export class JobSpeciality {
    id: number;
    name: String;
    icon: String;
    id_job: number;
}

@Injectable({
    providedIn: "root"
})
export class JobSpecialityAdapter implements Adapter<JobSpeciality> {
    adapt(res: any): JobSpeciality {
        const jobSpeciality : JobSpeciality = new JobSpeciality();
        jobSpeciality.id = res.id;
        jobSpeciality.name = res.name;
        jobSpeciality.icon = res.icon;
        jobSpeciality.id_job = res.id_job;
        return jobSpeciality;
    }
}