import { Adapter } from "./adapter";
import { Injectable } from "@angular/core";

export class ContactUs {
    email: number;
    phone: String;
    body: String;
}

@Injectable({
    providedIn: "root"
})
export class ContactUsAdapter implements Adapter<ContactUs> {
    adapt(res: any): ContactUs {
        const contactUs : ContactUs = new ContactUs();
        contactUs.email = res.email;
        contactUs.phone = res.phone;
        contactUs.body = res.body;
        return contactUs;
    }
}