import { Component } from '@angular/core';
import { slideInAnimation } from './route-animation';
import { ConfigService } from './config.service';
import { RouterOutlet } from '@angular/router';
import { NgcCookieConsentService, NgcInitializeEvent, NgcStatusChangeEvent, NgcNoCookieLawEvent } from 'ngx-cookieconsent';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from './api.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [ slideInAnimation ]
})
export class AppComponent {
  title = 'projet-rh';
  
  whiteMenu: boolean;
  displayMenu: boolean;

  constructor(private configService : ConfigService, private ccService: NgcCookieConsentService, private translateService: TranslateService, private apiService: ApiService) {
  }

  ngOnInit() {     
      this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
      // Override default messages with the translated ones
      this.ccService.getConfig().content.header = 'Des cookies sont utilisés sur ce site web.';
      this.ccService.getConfig().content.message = 'Ce site web utilise des cookies à des fins d\'authentification.';
      this.ccService.getConfig().content.dismiss = 'Ok';
      this.ccService.getConfig().content.allow = 'Autoriser les cookies';
      this.ccService.getConfig().content.deny = 'Refuser';
      this.ccService.getConfig().content.link = 'En savoir plus';
      this.ccService.getConfig().content.policy = 'Politique de cookies';
      this.ccService.getConfig().content.href  = 'http://www.asgoodaspay.com/legal-notice';
      this.ccService.destroy(); // remove previous cookie bar (with default messages)
      this.ccService.init(this.ccService.getConfig()); // update config with translated messages  
      this.apiService.refreshAccount();   
  };
  
  prepareRoute(outlet: RouterOutlet) {    
    if(outlet && outlet.activatedRouteData) {      
        const whiteMenu: boolean = outlet.activatedRouteData['white_menu']?true:false;
        if(whiteMenu!=this.whiteMenu) { 
          this.whiteMenu = whiteMenu; 
          setTimeout(() => {
            this.configService.whiteMenu=whiteMenu;
           });
        }

        const displayMenu: boolean = outlet.activatedRouteData['']==false?false:true; 
        if(displayMenu!=this.displayMenu) {   
          this.displayMenu = displayMenu;       
          setTimeout(() => {
            this.configService.displayMenu=displayMenu;
          });
        }
    }
    
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  } 
}
