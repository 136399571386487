import { Adapter } from "./adapter";
import { Injectable } from "@angular/core";
import { JobSpeciality } from "./job-speciality.model";

export class CandidateJobSpeciality {
    job_speciality: JobSpeciality;

    constructor(job_speciality: JobSpeciality) {
        this.job_speciality = job_speciality;
    }
}

@Injectable({
    providedIn: "root"
})
export class JobAdapter implements Adapter<CandidateJobSpeciality> {
    adapt(res: any): CandidateJobSpeciality {
        const candidateJobSpeciality : CandidateJobSpeciality = new CandidateJobSpeciality(null);

        candidateJobSpeciality.job_speciality = res.job_speciality;
        return candidateJobSpeciality;
    }
}