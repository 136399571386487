import { Adapter } from "./adapter";
import { Injectable } from "@angular/core";

export class Feedback {
    feedback: string;
    value: number;
    comment: string;
}

@Injectable({
    providedIn: "root"
})
export class FeedbackAdapter implements Adapter<Feedback> {
    adapt(res: any): Feedback {
        const feedback : Feedback = new Feedback();
        feedback.feedback = res.feedback;
        feedback.value = res.value;
        feedback.comment = res.comment;
        return feedback;
    }
}