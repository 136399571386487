import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../api.service';
import { Candidate } from '../candidate.model';
import { Account } from '../account.model';
import { PriceResult } from '../price-result.model';
import { EntrySelector } from '../selector/selector.component';
import { ResearchStatus } from '../research-status.enum';
import { RangeEntry } from '../range-entry.model';
import { SalaryPeriod } from '../salary-period.enum';
import { LanguageLevel } from '../language-level.enum';
import { ActivatedRoute, Params } from "@angular/router";
import { Feedback } from '../feedback.model';

@Component({
  selector: 'app-calcul-result',
  templateUrl: './calcul-result.component.html',
  styleUrls: ['./calcul-result.component.scss']
})
export class CalculResultComponent implements OnInit {

  candidate: Candidate;
  account: Account;

  priceResult: PriceResult;
  loading: boolean;
  loadingStep: number;
  
  entriesResearchStatus: EntrySelector<ResearchStatus>[];
  entriesAvailability: EntrySelector<RangeEntry>[];
  availability: RangeEntry;
  salaryIncrease: number;
  feedback: Feedback = new Feedback();
  feedbackSaved: boolean = false;

  displayResult: boolean = false;
  //displayResult: boolean = true;

  displayFormContact: boolean = false;

  fileUploaded: boolean = false;
  availabilityEnabled: boolean = true;
  formContactValidated: boolean = false;
  
  constructor(private apiService: ApiService, private route: ActivatedRoute, private cdRef: ChangeDetectorRef) {
     
  } 

  async initPage() {
    const strCandidate = localStorage.getItem('candidate');
    this.candidate = JSON.parse(strCandidate);
    
    if(localStorage.hasOwnProperty('account')) {
      this.account = JSON.parse(localStorage.getItem('account'));
    } else {      
      await this.getAccount();
    }
    
    if(!this.account) {
      this.account = new Account();
    }
    
    await this.apiService.calculate(this.candidate).subscribe((priceResult)=>{      
      (async () => {         
        await this.delay(1000*2); // Wait 2 sec
        this.loadingStep = 2;

        this.priceResult = priceResult;      

        const annualSalary =  this.getAnnualSalary();
        this.salaryIncrease = ((this.priceResult.maxPrice*1000)- annualSalary) / annualSalary;

        await this.delay(1000*2); // Wait 2 sec
        this.loadingStep = 3;
    
        await this.delay(1000*1); // Wait 1 sec
        this.loading=false;
        
        this.cdRef.detectChanges();
      })();
    });     
  }
  
  ngOnInit() {
    this.initPage();

    this.entriesResearchStatus = new Array<EntrySelector<ResearchStatus>>();
    this.entriesResearchStatus.push(new EntrySelector<ResearchStatus>(ResearchStatus.LISTENING, null, "A l’écoute"));
    this.entriesResearchStatus.push(new EntrySelector<ResearchStatus>(ResearchStatus.NOT_WISH_TO_CHANGE, null, "En recherche active "));
    this.entriesResearchStatus.push(new EntrySelector<ResearchStatus>(ResearchStatus.WORKING, null, "Ne souhaite pas changer de job"));
    
    this.entriesAvailability = new Array<EntrySelector<RangeEntry>>();
    this.entriesAvailability.push(new EntrySelector<RangeEntry>(new RangeEntry(0, 0), null, "Immédiatement"));
    this.entriesAvailability.push(new EntrySelector<RangeEntry>(new RangeEntry(1, 1), null, "Sous 1 mois"));
    this.entriesAvailability.push(new EntrySelector<RangeEntry>(new RangeEntry(1, 2), null, "Entre 1 et 2 mois"));
    this.entriesAvailability.push(new EntrySelector<RangeEntry>(new RangeEntry(3, 3), null, "Sous 3 mois"));
    this.entriesAvailability.push(new EntrySelector<RangeEntry>(new RangeEntry(6, 6), null, "Sous 6 mois"));

    this.loading=true;
    this.loadingStep = 1;    
    
    this.route.params.subscribe(async params => {
      console.log(params);
      if(params.idCandidate != undefined) {
        const idCandidate : number = JSON.parse(params.idCandidate);
        
        await this.getAccount();  
        this.apiService.getCandidate(idCandidate).subscribe((candidate) => {
          this.candidate = candidate;
          console.log('candidate');
          console.log(this.candidate);
          this.localSave();
          this.initPage();
          this.displayResult=true;
      });  
      } else {
        this.initPage();
      }
    });   
  }

  async getAccount() {
    await this.apiService.getMyAccount().subscribe((account) => {
      if (!account) { return; }
      this.account = account;
      console.log('account');
      console.log(this.account);
      
  });
  }

  public async save() {
    /*
    console.log('S1');
    if(localStorage.hasOwnProperty('account')) {
      this.account = JSON.parse(localStorage.getItem('account'));
    } 
    console.log('S2');
*/
    this.candidate.account = new Account();
    this.candidate.account.id = this.account.id; 
    console.log('save:');
    console.log(this.candidate); 
    await this.saveCandidate();

    this.displayResult = true;
  }
  
  private async saveCandidate() { 
    if(this.candidate.id!=null) {
      await this.apiService.patchCandidate(this.candidate).subscribe((id)=>{
      }); 
    } else {
      await this.apiService.postCandidate(this.candidate).toPromise().then((id)=>{
        this.candidate.id = Number(id);  
      });
    }
    this.localSave();
  }

  public localSave() {      
    localStorage.setItem('candidate', JSON.stringify(this.candidate));
    localStorage.setItem('account', JSON.stringify(this.account));
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  getAnnualSalary() : number {
    let multiple: number;
    switch(this.candidate.salary_period) {
      case SalaryPeriod.ANNUAL: multiple = 1; break;
      case SalaryPeriod.MONTHLY: multiple = 12; break;
    }
    // Conversion NET -> BRUT
    return this.candidate.salary * multiple;
  }

  getJobDescription() : string {
    let jobDescription: string = '';
    for(var i = 0; i < this.candidate.candidate_job_specialities.length; i++) {
      if(i>0) jobDescription+=" / ";
      jobDescription+=this.candidate.candidate_job_specialities[i].job_speciality.name;
    };
    return jobDescription;
  }

  getLanguageLevelDescription(languageLevel: LanguageLevel) : string {
    switch(languageLevel.toString()) {
      case 'BILINGUAL':
        return "bilingue";
      case 'FLUENT':
        return "courant";
      case 'OPERATIONAL':
        return "opérationel";
      case 'TECHNICAL':
        return "technique";
      case 'NONE':
        return "aucun";
    }
  }

  convertAnnualGrossToMonthlyNet(annualGross : number) : number {
    return (annualGross / 12) * 0.785;
  }

  showFormContact() {
    this.displayFormContact=true;
    this.candidate.accept_contact;
  }

  onValidate() {
    this.formContactValidated = true;

    this.apiService.onValidateCandidate(this.candidate).toPromise().then((id)=>{
      this.candidate.id = Number(id);       
    });
  }

  async savePartialFeedback() {
    if (this.candidate.id == null) return;

    if (this.feedback.feedback === 'JUSTE') {
      this.feedback.comment = null;
      this.feedback.value = null;
    }
    await this.apiService.setCandidateFeedback(this.candidate, this.feedback).subscribe();
  }

  async saveFeedback() {
    await this.savePartialFeedback();
    this.feedbackSaved = true;
  }
}
