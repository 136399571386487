import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl} from '@angular/forms';
import {MatAutocomplete} from '@angular/material/autocomplete';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-chip-selector',
  templateUrl: './chip-selector.component.html',
  styleUrls: ['./chip-selector.component.scss']
})
export class ChipSelectorComponent<T> implements OnInit {

  _entries: EntrySelector<T>[];
  @Input('placeholder') placeholder: String;
  @Input('label') label: String;
  @Input('width') width: number;
  @Input('required') required: boolean;
  @Output() onValueChange = new EventEmitter<T[]>();
  selectedValues: T[];

  values2: T[];

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  inputCtrl = new FormControl();
  filteredInputs: EntrySelector<T>[];
  inputControl = new FormControl();

  @ViewChild('chipInput', {static: false}) chipInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;

  constructor() { 
    this.inputCtrl.valueChanges.subscribe((value) => { 
      this.refreshFilter();
    });
  }

  ngOnInit() {
  }

  @Input()
  set entries(entries: EntrySelector<T>[]) {
    this._entries = entries;
    this.refreshFilter();
  }

  get entries() {
    return this._entries;
  }

  @Input()
  get values() {
    return this.selectedValues;
  }

  set values(vals: T[]) {
    this.selectedValues = vals;
    this.onValueChange.emit(this.selectedValues);
  }

  add(value: T) {    
    const index = this.selectedValues.indexOf(value);
    if (index < 0) {
      this.selectedValues.push(value);
      this.onValueChange.emit(this.selectedValues);
      this.refreshFilter();
    }
    console.log(this.values);
  }

  remove(value: T): void {
    const index = this.selectedValues.indexOf(value);

    if (index >= 0) {
      this.selectedValues.splice(index, 1);
      this.onValueChange.emit(this.selectedValues);
      this.refreshFilter();
    }
  }

  compareFn(c1: T, c2: T): boolean {
    return JSON.stringify(c1) == JSON.stringify(c2);
  }

  filter(filter: string): EntrySelector<T>[] {
    if(this.entries==null) return this.entries;
    
    if (filter!=null && filter!=undefined) {
      return this.entries.filter(entry => {
        return entry && entry.name
        && typeof entry.name === 'string'
        && typeof filter === 'string'
        && entry.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0 && !this.isSelected(entry.value);
      })
    } else {
      return this.entries.filter(entry => {
        return !this.isSelected(entry.value);
      });
    }
  }

  refreshFilter() {
    this.filteredInputs = this.filter(this.inputCtrl.value);
  }
  
  isSelected(value: T) {
    if(this.values==null) return false;
    return this.values.indexOf(value)>=0;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer !== event.container) {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    } else {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
    this.values = this.values;
  }
}

export class EntrySelector<T> {  
  name: String;
  value: T;
  tooltip: String;

  constructor(value: T, name: String, tooltip: String = null) { 
    this.value = value;
    this.name = name;
    this.tooltip = tooltip;
  }
}
