export enum AcademicLevelType
{
	NIVEAU_BAC,
	DUT_BTS_BAC2,
	LICENCE_BAC3,
	MASTER1_IEP_IUP_BAC4,
	MASTER2_DESS_DEA_BAC5,
	ECOLE_INGENIEUR,
	DOCTORAT_CYCLE3,
	AUTRES
}
