import { Adapter } from "./adapter";
import { Injectable } from "@angular/core";
import { Technology } from "./technology.model"

export class JobTechnology {
    id: number;
    id_job: number;
    technology: Technology;
}

@Injectable({
    providedIn: "root"
})
export class JobTechnologyAdapter implements Adapter<JobTechnology> {
    adapt(res: any): JobTechnology {
        const jobTechnology : JobTechnology = new JobTechnology();
        jobTechnology.id = res.id;
        jobTechnology.id_job = res.id_job;
        jobTechnology.technology = res.technology;
        return jobTechnology;
    }
}