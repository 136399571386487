import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../api.service';
import { Candidate } from '../candidate.model';
import { Account } from '../account.model';
import { EntrySelector } from '../selector/selector.component';
import { ResearchStatus } from '../research-status.enum';
import { RangeEntry } from '../range-entry.model';
import { Validators, FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-form-contact',
  templateUrl: './form-contact.component.html',
  styleUrls: ['./form-contact.component.scss']
})
export class FormContactComponent implements OnInit {

  _candidate: Candidate;

  @Input('candidate')
  get candidate() : Candidate {
    return this._candidate;
  }

  set candidate(candidate: Candidate) {
    this._candidate = candidate;
  }

  _account: Account;

  @Input('account')
  get account() : Account {
    return this._account;
  }

  set account(account: Account) {
    this._account = account;
    this._availability = this.getAvailability();
    console.log('setAccount: ', account);
    this.loaded = true;
  }

  entriesResearchStatus: EntrySelector<ResearchStatus>[];
  entriesAvailability: EntrySelector<RangeEntry>[];

  displayResult: boolean = true;
  displayFormContact: boolean = false;

  updatePassword: boolean = false;

  @Input('mode')
  mode: String;

  @Input('validateButtonLabel')
  validateButtonLabel: String = "Valider";

  @Input('validateButton')
  validateButton: boolean = true;

  _light: Boolean;
  loaded: Boolean = false;

  error: String;

  @Input('light')
  set light(light: Boolean) { 
    this._light = Boolean(light);
  }

  get light() {
    return this._light;
  }

  researchStatusEnabled: boolean = false;
  availabilityEnabled: boolean = false;
  firstNameEnabled: boolean = false;
  lastNameEnabled: boolean = false;
  phoneEnabled: boolean = false;
  emailEnabled: boolean = false;
  urlLinkedinEnabled: boolean = false;
  passwordEnabled: boolean = false;
  _availability: RangeEntry;
  passwordUpdateEnabled: boolean = true;
  newPassword: String;

  @Output() onValidate = new EventEmitter();

  @Input() formContactValidated: Boolean;
  @Output() formContactValidatedChange = new EventEmitter<Boolean>();

  emailValid: boolean;
  phoneValid: boolean;

  formContact: FormGroup;

  constructor(private apiService: ApiService) {
    this.entriesResearchStatus = new Array<EntrySelector<ResearchStatus>>();
    this.entriesResearchStatus.push(new EntrySelector<ResearchStatus>(ResearchStatus.LISTENING, null, "A l’écoute"));
    this.entriesResearchStatus.push(new EntrySelector<ResearchStatus>(ResearchStatus.NOT_WISH_TO_CHANGE, null, "En recherche active "));
    this.entriesResearchStatus.push(new EntrySelector<ResearchStatus>(ResearchStatus.WORKING, null, "Ne souhaite pas changer de job"));

    this.entriesAvailability = new Array<EntrySelector<RangeEntry>>();
    this.entriesAvailability.push(new EntrySelector<RangeEntry>(new RangeEntry(0, 0), null, "Immédiatement"));
    this.entriesAvailability.push(new EntrySelector<RangeEntry>(new RangeEntry(1, 1), null, "Sous 1 mois"));
    this.entriesAvailability.push(new EntrySelector<RangeEntry>(new RangeEntry(1, 2), null, "Entre 1 et 2 mois"));
    this.entriesAvailability.push(new EntrySelector<RangeEntry>(new RangeEntry(3, 3), null, "Sous 3 mois"));
    this.entriesAvailability.push(new EntrySelector<RangeEntry>(new RangeEntry(6, 6), null, "Sous 6 mois"));
  }

  ngOnInit() {
    console.log('mode: ', this.mode);
    switch(this.mode) {
      case 'account':
        this.researchStatusEnabled = true;
        this.availabilityEnabled = true;
        this.firstNameEnabled = true;
        this.lastNameEnabled = true;
        this.phoneEnabled = true;
        this.emailEnabled = true;
        this.urlLinkedinEnabled = true;
        this.passwordEnabled = true;
        this.formContact = new FormGroup({
          researchStatus: new FormControl('',[]),
          availability: new FormControl('',[]),
          firstName: new FormControl('',[Validators.required]),
          lastName: new FormControl('',[Validators.required]),
          phone: new FormControl('',[Validators.required, Validators.pattern("^[0-9]{10}$")]),
          email: new FormControl('',[Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
          linkedin_url: new FormControl('',[]),
          password: new FormControl('',[]),
        });
        break;        
      case 'signup-light':        
        this.researchStatusEnabled = true;
        this.availabilityEnabled = true;
        this.firstNameEnabled = true;
        this.lastNameEnabled = true;
        this.phoneEnabled = true;
        this.emailEnabled = true;
        this.urlLinkedinEnabled = true;
        this.passwordEnabled = true;
        this.passwordUpdateEnabled = false;

        this.formContact = new FormGroup({
          researchStatus: new FormControl('',[Validators.required]),
          availability: new FormControl('',[Validators.required]),
          firstName: new FormControl('',[Validators.required]),
          lastName: new FormControl('',[Validators.required]),
          phone: new FormControl('',[Validators.required, Validators.pattern("^[0-9]{10}$")]),
          email: new FormControl('',[Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
          linkedin_url: new FormControl('',[]),
          password: new FormControl('',[]),
          document: new FormControl('',[]),
        });

        break;
      case 'signup':
        this.researchStatusEnabled = true;
        this.availabilityEnabled = true;
        this.firstNameEnabled = true;
        this.lastNameEnabled = true;
        this.phoneEnabled = true;
        this.emailEnabled = true;
        this.urlLinkedinEnabled = true;
        this.passwordEnabled = true;
        
        this.formContact = new FormGroup({
          researchStatus: new FormControl('',[Validators.required]),
          availability: new FormControl('',[Validators.required]),
          firstName: new FormControl('',[Validators.required]),
          lastName: new FormControl('',[Validators.required]),
          phone: new FormControl('',[Validators.required, Validators.pattern("^[0-9\]{10}$")]),
          email: new FormControl('',[Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
          linkedin_url: new FormControl('',[]),
          password: new FormControl('',[Validators.required]),
        });        
        break;
      case 'calcul-result':
        this.researchStatusEnabled = true;
        this.availabilityEnabled = true;
        this.firstNameEnabled = true;
        this.lastNameEnabled = true;
        this.phoneEnabled = true;
        this.emailEnabled = true;
        this.urlLinkedinEnabled = true;
        this.passwordEnabled = true;
        this.passwordUpdateEnabled = false;

        this.formContact = new FormGroup({
          researchStatus: new FormControl('',[Validators.required]),
          availability: new FormControl('',[Validators.required]),
          firstName: new FormControl('',[Validators.required]),
          lastName: new FormControl('',[Validators.required]),
          phone: new FormControl('',[Validators.required, Validators.pattern("^[0-9]{10}$")]),
          email: new FormControl('',[Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
          linkedin_url: new FormControl('',[]),
          password: new FormControl('',[]),
        });
        break;
    }
  }

  public async validate() {
    console.log('validate', this.newPassword);
    this.account.password = this.newPassword;
    console.log('account', this.account);
    if(await this.save()) {
      console.log('validate OK');
      this.formContactValidated = true;
      this.formContactValidatedChange.emit(this.formContactValidated);
      this.onValidate.emit();
    }
  }

  public async save() : Promise<Boolean> {
    if(await this.saveAccount()) {
      console.log('save OK');
      this.updatePassword = false;
      return true;
    }
    return false;
  }

  private async saveAccount(): Promise<Boolean> {
    if(!this.account) return false;

    if(this.account.id!=null) {
      await this.apiService.patchAccount(this.account).toPromise().then((id)=>{
        console.log('OK: ', id);    
        this.error = null;
        return true;
      }).catch((error) => { 
        console.log('Err: ', error);  
        this.error = error.error.message;
        return false;
      });
    } else {    
      await this.apiService.postAccount(this.account).toPromise().then((id)=>{
        console.log('OK: ', id);    
        this.error = null;
        this.account.id = id;   
        return true;    
      }).catch((error) => { 
        console.log('Err: ', error);  
        this.error = error.error.message;
        return false;
      });
    }

    if(this.account.id==null) return false;

    if(this.account.email && this.account.password) {
      await this.apiService.login(this.account.email.toString(), this.account.password.toString());
    }

    if (this.candidate) {
      this.candidate.account = new Account();
      this.candidate.account.id = this.account.id;
    }

    this.localSave();
    return true;
  }

  public localSave() {      
    localStorage.setItem('account', JSON.stringify(this.account));
    if(this.candidate) localStorage.setItem('candidate', JSON.stringify(this.candidate));
  }

  public setResearchStatus() {
    if(this.account.research_status==undefined) return;
    if(this.account.research_status != ResearchStatus.WORKING) {
      this.availabilityEnabled = true;
    } else {
      this.availabilityEnabled = false;
      this._availability = null;
    }
  }

  public getAvailability() : RangeEntry {   
    if(!this.account) return null;
    for(var i=0; i<this.entriesAvailability.length; i++) {
      if(this.entriesAvailability[i].value.min != this.account.min_month_availability) continue;
      if(this.entriesAvailability[i].value.max != this.account.max_month_availability) continue;
      return this.entriesAvailability[i].value;
    }
    return null;
  }

  @Input('availability')
  public setAvailability(availability) : RangeEntry {    
    if(!this.candidate) return;
    if(!availability) return;
    this.account.min_month_availability=availability.min;
    this.account.max_month_availability=availability.max;     
  }

  uploadFile(files: FileList) {    
    for(var i=0; i<files.length; i++) {
      this.apiService.postAccountDocument(this.account, files.item(0)).subscribe((priceResult)=>{
        console.log('upload OK');
      });
    }
    this.candidate.hashDocumentUploaded = true;
  }
/*
  test() {
    const invalid = [];
    const controls = this.formContact.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }
    console.log('errors', invalid);
    console.log('this.formContact', this.formContact);
  }
  */
}
