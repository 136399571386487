import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-multiple-tile-selector',
  templateUrl: './multiple-tile-selector.component.html',
  styleUrls: ['./multiple-tile-selector.component.scss']
})
export class MultipleTileSelectorComponent<T> implements OnInit {

  @Input('entries') entries: EntryTileSelector<T>[];
  @Input('maxSelected') maxSelected: number;
  @Input('cols') cols: number;
  @Output() onValueChange = new EventEmitter<T[]>();
  selectedValues: T[];

  constructor() { }

  ngOnInit() {
  }

  @Input()
  get value() {
    return this.selectedValues;
  }

  set value(val: T[]) {

    const selectedValues = [];
    for(let i=0;i<val.length;i++) {
      for(let j=0;j<this.entries.length;j++) {
        if(this.compare(val[i], this.entries[j].value)) {
          selectedValues.push(this.entries[j].value);
          break;
        }        
      }
    }

    if(!this.compare(this.selectedValues, selectedValues)) {
      this.onValueChange.emit(this.selectedValues);
      this.selectedValues = selectedValues;
    }
  }

  switch(val: T) {
    if(this.contains(val)) {
      this.remove(val);
    } else {
      this.add(val);
    }
  }

  contains(val: T) {
    return this.selectedValues.indexOf(val)!==-1;
  }

  add(val: T) {
    if(this.maxSelected !=null && this.selectedValues.length >= this.maxSelected) return;
    this.selectedValues.push(val);
    console.log('add');
  //  console.log(this.selectedValues);
    this.onValueChange.emit(this.selectedValues);
  }

  remove(val: T) {
    var i = this.selectedValues.indexOf(val);
    if (i !== -1) this.selectedValues.splice(i, 1);
    else {
      console.error('Unable to delete entry: ' + val);
    }

    console.log('remove');
  //  console.log(this.selectedValues);
    this.onValueChange.emit(this.selectedValues);
  }

  compare(obj1, obj2) {
    if(obj1==undefined) return false;
    if(obj2==undefined) return false;

    //Loop through properties in object 1
    for (var p in obj1) {
      //Check property exists on both objects
      if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;
   
      switch (typeof (obj1[p])) {
        //Deep compare objects
        case 'object':
          if (!this.compare(obj1[p], obj2[p])) return false;
          break;
        //Compare function code
        case 'function':
          if (typeof (obj2[p]) == 'undefined' || (p != 'compare' && obj1[p].toString() != obj2[p].toString())) return false;
          break;
        //Compare values
        default:
          if (obj1[p] != obj2[p]) return false;
      }
    }
   /*
    //Check object 2 for any extra properties
    for (var p in obj2) {
      if (typeof (obj1[p]) == 'undefined') { console.log('ok5: ' +p); return false; }
    }
    */
    return true;
  }
}

export class EntryTileSelector<T> {  
  name: String;
  icon: String;
  value: T;

  constructor(value: T, icon: String, name: String) { 
    this.value = value;
    this.icon = icon;
    this.name = name;
  }
}
