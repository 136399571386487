import { Adapter } from "./adapter";
import { Injectable } from "@angular/core";
import { ParameterType } from './parameter-type.enum'

export class CandidateParameter {
    id: number;
    name: ParameterType;
    value: string;

    constructor(name: ParameterType, value: string) {
        this.name = name;
        this.value = value;
    }
}

@Injectable({
    providedIn: "root"
})
export class JobAdapter implements Adapter<CandidateParameter> {
    adapt(res: any): CandidateParameter {
        const candidateParameter : CandidateParameter = new CandidateParameter(null, null);

        candidateParameter.id = res.id;
        candidateParameter.name = res.name;
        candidateParameter.value = res.value;
        return candidateParameter;
    }
}