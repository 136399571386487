import { NgModule, Injectable } from '@angular/core';
import { Routes, RouterModule, RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle} from '@angular/router';
import { CalculResultComponent } from './calcul-result/calcul-result.component';
import { HomeComponent } from './home/home.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { AccountComponent } from './account/account.component';
import { CreateAccountComponent } from './create-account/create-account.component';
import { ConfigService } from './config.service';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { LegalNoticeComponent } from './legal-notice/legal-notice.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { CandidateGuideComponent } from './candidate-guide/candidate-guide.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CguComponent } from './cgu/cgu.component';

const routes: Routes = [
  { path: '', component: HomeComponent, data: {animation: 'home', white_menu: true} },
  { path: 'home', component: HomeComponent, data: {animation: 'home', white_menu: true} },
  { path: 'signup', component: SignUpComponent, data: {animation: 'signup', display_menu: false } },
  { path: 'signup-new/:mode', component: SignUpComponent, data: {animation: '' } },
  { path: 'signup/:step', component: SignUpComponent, data: {animation: 'signup/step', display_menu: false } },
  { path: 'result', component: CalculResultComponent, data: {animation: 'result'} },
  { path: 'result/:idCandidate', component: CalculResultComponent, data: {animation: 'result/idCandidate'} },
  { path: 'account', component: AccountComponent, data: {animation: ''} },
  { path: 'create-account', component: CreateAccountComponent, data: {animation: ''} },
  { path: 'logout', component: AccountComponent, data: {animation: '', logout: true} },
  { path: 'contact-us', component: ContactUsComponent, data: {animation: ''} },
  { path: 'legal-notice', component: LegalNoticeComponent, data: {animation: ''} },
  { path: 'about-us', component: AboutUsComponent, data: {animation: ''} },
  { path: 'candidate-guide', component: CandidateGuideComponent, data: {animation: ''} },
  { path: 'cgu', component: CguComponent, data: {animation: ''} },
  { path: 'forgot-password-confirmation', component: ForgotPasswordComponent, data: {animation: '', step: 'confirmation' } },
  { path: 'forgot-password', component: ForgotPasswordComponent, data: {animation: ''} },
];

@Injectable()
export class CustomReuseStrategy implements RouteReuseStrategy {

  handlers: {[key: string]: DetachedRouteHandle} = {};

  constructor(private configService : ConfigService) { 
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    this.handlers[route.routeConfig.path] = null;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    /*
    if(route && route.data) {
      this.configService.whiteMenu=route.data['white_menu']?true:false; 
      this.configService.displayMenu=route.data['display_menu']==false?false:true; 
    }
    */
    return !!route.routeConfig && !!this.handlers[route.routeConfig.path];
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    if (!route.routeConfig) {
      return null;
    }
    return this.handlers[route.routeConfig.path];
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
  //  console.log('shouldReuseRoute');
  //  return curr.component !== ItemComponent;
    return false;
  }
}


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [{
    provide: RouteReuseStrategy, 
    useClass: CustomReuseStrategy
  }],
  exports: [RouterModule]
})
export class AppRoutingModule { }

