import { Adapter } from "./adapter";
import { Injectable } from "@angular/core";
import { ResearchStatus } from "./research-status.enum";

export class Account {
    id: number;
    first_name: String;
    last_name: String;   
    linkedin_url: String
    email: String
    phone_number: String
    salt: String
    password: String
    research_status: ResearchStatus;
    min_month_availability: number;
    max_month_availability: number;
}

@Injectable({
    providedIn: "root"
})
export class AccountAdapter implements Adapter<Account> {
    adapt(res: any): Account {
        const account : Account = new Account();

        account.id = res.id;
        account.first_name = res.first_name;
        account.last_name = res.last_name;
        account.linkedin_url = res.linkedin_url;
        account.email = res.email;
        account.phone_number = res.phone_number;
        account.salt = res.salt;
        account.password = res.password;
        account.min_month_availability = res.min_month_availability;
        account.max_month_availability = res.max_month_availability;
        account.research_status = res.research_status;
        return account;
    }
}