import { Adapter } from "./adapter";
import { Injectable } from "@angular/core";
import { Job } from "./job.model";
import { LanguageLevel } from "./language-level.enum";
import { SalaryType } from "./salary-type.enum";
import { Account } from "./account.model";
import { JobRemote } from "./job-remote.enum";
import { SalaryPeriod } from "./salary-period.enum";
import { AcademicLevelType } from './academic-level-type.enum';
import { CandidateLocation } from './candidate-location.model';
import { CandidateJobSpeciality } from './candidate-job-speciality.model';
import { CandidateParameter } from './candidate-parameter.model';

export class Candidate {
    id: number;
    account: Account;
    job: Job;
    candidate_job_specialities: CandidateJobSpeciality[];
    candidate_parameters: CandidateParameter[];
    number_company_experience: number;
    month_experience: number;
    month_experience_job: number;
    candidate_level: number;
    month_experience_internship: number;
    month_longest_mission: number;
    number_employees_min_average_experience: number;
    number_employees_max_average_experience: number;
    company_sector: String;
    english_level: LanguageLevel;
    job_remote: JobRemote;
    locations: CandidateLocation[];
    max_distance_wishes_to_work: number;
    salary_period: SalaryPeriod;
    salary_type: SalaryType;
    salary: number;
    accept_contact: Boolean;
    academic_level: AcademicLevelType

    hashDocumentUploaded: boolean = false;
    
    constructor() { 
        this.locations=[];
        this.account = new Account();
    }
}

@Injectable({
    providedIn: "root"
})
export class JobAdapter implements Adapter<Candidate> {
    adapt(res: any): Candidate {
        const candidate : Candidate = new Candidate();

        candidate.id = res.id;
        candidate.account = res.account;
        candidate.job = res.job;
        candidate.candidate_job_specialities = res.candidate_job_specialities;
        candidate.candidate_parameters = res.candidate_parameters;
        candidate.month_experience = res.month_experience;
        candidate.month_experience_job = res.month_experience_job;
        candidate.candidate_level = res.candidate_level;
        candidate.month_experience_internship = res.month_experience_internship;
        candidate.month_longest_mission = res.month_longest_mission;
        candidate.number_employees_min_average_experience = res.number_employees_min_average_experience;
        candidate.number_employees_max_average_experience = res.number_employees_max_average_experience;
        candidate.company_sector = res.company_sector;
        candidate.english_level = res.english_level;
        candidate.job_remote = res.job_remote;
        candidate.locations = res.locations;
        candidate.max_distance_wishes_to_work = res.max_distance_wishes_to_work;
        candidate.salary_period = res.salary_period;
        candidate.salary_type = res.salary_type;
        candidate.salary = res.salary;
        candidate.accept_contact = res.accept_contact;
        candidate.academic_level = res.academic_level;
        return candidate;
    }
}