import { Adapter } from "./adapter";
import { Injectable } from "@angular/core";


export class CandidateLocation {
    id: number;
    name: string;
    latitude: number;
    longitude: number;

    constructor(name: string, latitude: number, longitude: number) {
        this.name = name;
        this.latitude = latitude;
        this.longitude = longitude;
    }
}

@Injectable({
    providedIn: "root"
})
export class JobAdapter implements Adapter<CandidateLocation> {
    adapt(res: any): CandidateLocation {
        const candidateLocation : CandidateLocation = new CandidateLocation(null, null, null);

        candidateLocation.id = res.id;
        candidateLocation.name = res.name;
        candidateLocation.latitude = res.latitude;
        candidateLocation.longitude = res.longitude;
        return candidateLocation;
    }
}