import { Component, OnInit, Input, Output, EventEmitter, forwardRef, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  providers: [
    { 
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputTextComponent),
    }
  ]
})
export class InputTextComponent implements OnInit, ControlValueAccessor {

  cdRef: ChangeDetectorRef;

  constructor(cdRef: ChangeDetectorRef) { 
    this.cdRef = cdRef; 
  }

  @Input('placeholder') placeholder: String;
  //@Input() value: string;
  @Input() label: string;
  @Input('type') type: string;
  @Output() valueChange = new EventEmitter<string>();
  @Output() onValueChange = new EventEmitter<string>();
  @Input('width') width: number;
  @Input('required') required: boolean;
  @Input('error') error: Boolean;
  _value: string;

  ngOnInit() {
  }

  onChange(value) {
    console.log('onchange');
  }
  
  writeValue(val: any): void {
    //console.log('writeValue', val);
    this.value = val;
  }
 
  registerOnChange(fn: (_: any) => void): void {
     this.onChange = fn;
     this.onChange(this.value);
  }
 
  registerOnTouched(fn: any): void {
  }
  
  get value() {
    return this._value;
  }

  set value(value: string) {
    this._value = value;
    //console.log('setValue input', this._value);
    
    //this.error = false;
    this.valueChange.emit(value);
    this.onValueChange.emit(value);
  }
}