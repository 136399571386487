import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { Job, JobAdapter } from './job.model';
import { JobSpeciality, JobSpecialityAdapter } from './job-speciality.model';
import { JobTechnology, JobTechnologyAdapter } from './job-technology.model';
import { Technology, TechnologyAdapter } from './technology.model';
import { Candidate } from './candidate.model';
import { Account } from './account.model';
import { ContactUs } from './contact-us.model';
import { PriceResult } from './price-result.model';
import { ConfigService } from './config.service';
import { Feedback } from './feedback.model';
import { Document } from './document.model';

const SESSION_ID_KEY = 'sesssionId';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  //private BASE_URL: string = 'http://localhost:8080';
  private BASE_URL: string = 'https://www.asgoodaspay.com/api';

  constructor(private httpClient: HttpClient
            , private jobAdapter: JobAdapter
            , private jobSpecialityAdapter: JobSpecialityAdapter
            , private technologyAdapter: TechnologyAdapter
            , private jobTechnologyAdapter: JobTechnologyAdapter
            , private configService : ConfigService) { 
  }

  public refreshAccount() {
    console.log('refreshAccount');
    this.getMyAccount().subscribe((account: Account)=>{ 
      if(account) this.configService.setAccount(account);
    });
  }

  private setSessionId(sessionId: string) {
    console.log('setSessionId: ' + sessionId);
    localStorage.setItem(SESSION_ID_KEY, sessionId);
  }

  private getSessionId(): string {
    return localStorage.getItem(SESSION_ID_KEY);
  }

  public getJobs(): Observable<Job[]> {
    return this.httpClient.get(this.BASE_URL + '/jobs').pipe(
      map((data: any[]) => data.map(item => this.jobAdapter.adapt(item)))
    );
  }

  public getJobSpecialities(idJob: number): Observable<JobSpeciality[]> {
    return this.httpClient.get(this.BASE_URL + '/jobs/' + idJob + '/specialities').pipe(
      map((data: any[]) => data.map(item => this.jobSpecialityAdapter.adapt(item)))
    );
  }

  public getTechnologies() : Observable<Technology[]> {
    return this.httpClient.get(this.BASE_URL + '/technologies').pipe(
      map((data: any[]) => data.map(item => this.technologyAdapter.adapt(item)))
    );
  }

  public getJobTechnologies(idJob: number): Observable<JobTechnology[]> {
    return this.httpClient.get(this.BASE_URL + '/jobs/' + idJob + '/technologies').pipe(
      map((data: any[]) => data.map(item => this.jobTechnologyAdapter.adapt(item)))
    );
  }

  public postCandidate(candidate: Candidate): Observable<number> {
     return this.post(this.BASE_URL + '/candidates', candidate);
  }

  public patchCandidate(candidate: Candidate): Observable<number> {
     return this.patch(this.BASE_URL + '/candidates/' + candidate.id, candidate);
  }
  
  public onValidateCandidate(candidate: Candidate): Observable<number> {
    return this.post(this.BASE_URL + '/candidates/' + candidate.id + "/actions/validate" , null);
 }
  
  public setCandidateFeedback(candidate: Candidate, feedback: Feedback): Observable<number> {
    return this.post(this.BASE_URL + '/candidates/' + candidate.id + '/actions/feedback', JSON.stringify(feedback));
 }

  public postAccount(account: Account): Observable<number> {
     return this.post(this.BASE_URL + '/accounts', account);
  }

  public patchAccount(account: Account): Observable<number> {
     return this.patch(this.BASE_URL + '/accounts/' + account.id, account);
  }

  public postAccountDocument(account: Account, document: File): Observable<string> {
    return this.post(this.BASE_URL + '/accounts/' + account.id + '/documents', document);
  }
 
  public calculate(candidate: Candidate): Observable<PriceResult> {
    return this.post(this.BASE_URL + '/candidates/actions/calculate', candidate);
  }

  public contactUs(contactUs: ContactUs): Observable<void> {
    return this.post(this.BASE_URL + '/contact-us', contactUs);
  }

  public forgotPassword(email: string): Observable<void> {
    return this.post(this.BASE_URL + '/accounts/actions/forgot-password', null, new HttpParam('email', email));
  }

  public forgotPasswordConfirmation(id: number, hash: string, password: string) : Observable<void> {
    return this.post(this.BASE_URL + '/accounts/' + id + '/actions/forgot-password-confirmation', null,
    new HttpParam('hash', hash), new HttpParam('password', password));
  }

  public async login(email: string, password: string) {
    try {
      const sessionId: string = await this.post(this.BASE_URL + '/accounts/actions/login', null, new HttpParam('email', email), new HttpParam('password', password)).toPromise();
      console.log('sessionId post login', sessionId);
      if(sessionId != null) {
        this.setSessionId(sessionId);
        this.refreshAccount();
      } else {
        console.log('vidage sessionId post login');
        this.setSessionId(null);
      }
    } catch(e) {
      return e.status;
    }
  }

  public async logout()  {
    await this.post(this.BASE_URL + '/accounts/actions/logout', null).toPromise();
    console.log('vidage sessionId logout');
    this.setSessionId(null);
    this.configService.account = null;
    localStorage.removeItem('account');
  }

  public getMyAccount() : Observable<Account> {
    return this.get(this.BASE_URL + '/accounts/my-account');
  }

  public getCandidates() : Observable<Candidate[]> {
    return this.get(this.BASE_URL + '/candidates');
  }

  public getCandidate(id: number) : Observable<Candidate> {
    return this.get(this.BASE_URL + '/candidates/' + id);
  }

  public deleteCandidate(id: number) : Observable<Candidate> {
    return this.delete(this.BASE_URL + '/candidates/' + id);
  }

  public getDocuments(account: Account): Observable<Document[]> {
    return this.get(this.BASE_URL + '/accounts/' + account.id + '/documents');
  }

  public getDocumentContent(account: Account, idDocument: number): Observable<any> {

    const url = this.BASE_URL + '/accounts/' + account.id + '/documents/' + idDocument + '/content';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      }),
      withCredentials: true,
      responseType: 'blob' as 'json'
    };

    const sessionId: string = this.getSessionId();
    if (sessionId != null) {
      httpOptions.headers = httpOptions.headers.append('Authorization', 'Bearer ' + sessionId);
    }
    return this.httpClient.get(url, httpOptions);
  }

  public getDocument(account: Account, idDocument: number): Observable<Document> {
    return this.get(this.BASE_URL + '/accounts/' + account.id + '/documents/' + idDocument);
  }

  public deleteDocument(account: Account, idDocument: number): Observable<Document> {
    return this.delete(this.BASE_URL + '/accounts/' + account.id + '/documents/' + idDocument);
  }

  public addDocument(account: Account, file: File): Observable<void> {
    return this.postFile(this.BASE_URL + '/accounts/' + account.id + '/documents', file);
  }

  private postFile(url, file: File): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
   //     'Content-Type':  'multipart/form-data',
      }),
      withCredentials: true,
    };
    const formData = new FormData();
    formData.append('file', file);
    const sessionId: string = this.getSessionId();
    if(sessionId != null) {
      httpOptions.headers = httpOptions.headers.append('Authorization', 'Bearer ' + sessionId);
    }
    return this.httpClient.post(url, formData);
  }

  private post(url, object, ...params: HttpParam[]): Observable<any> {
    let contentType = 'application/json';
    let data = object;

    if(params.length > 0) {
      data = '';
      for (let i = 0; i < params.length; i++) {
        if (i > 0) data += '&';
        data += params[i].name + '=' + params[i].value;
      }
      contentType = 'application/x-www-form-urlencoded';
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  contentType,
      }),
      withCredentials: true,
    };
    const sessionId: string = this.getSessionId();
    if(sessionId != null) {
      httpOptions.headers = httpOptions.headers.append('Authorization', 'Bearer ' + sessionId);
    }
    return this.httpClient.post(url, data, httpOptions);
  }

  private patch(url, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      }),
      withCredentials: true,
    };
    const sessionId: string = this.getSessionId();
    if(sessionId != null) {
      httpOptions.headers = httpOptions.headers.append('Authorization', 'Bearer ' + sessionId);
    }
    return this.httpClient.patch(url, data, httpOptions);
  }

  private get(url): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      }),
      withCredentials: true,
    };

    const sessionId: string = this.getSessionId();
    if (sessionId != null) {
      httpOptions.headers = httpOptions.headers.append('Authorization', 'Bearer ' + sessionId);
    }
    return this.httpClient.get(url, httpOptions);
  }

  private delete(url): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
      }),
      withCredentials: true,
    };
    const sessionId: string = this.getSessionId();
    if (sessionId != null) {
      httpOptions.headers = httpOptions.headers.append('Authorization', 'Bearer ' + sessionId);
    }
    return this.httpClient.delete(url, httpOptions);
  }
}

export class HttpParam {
  name: string;
  value: string;

  constructor(name: string, value: string) {
    this.name = name;
    this.value = value;
  }
}
