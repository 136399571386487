import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import {scan} from 'rxjs/operators';

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss'],
  providers: [
    { 
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectorComponent),
    }
  ]
})
export class SelectorComponent<T> implements OnInit, ControlValueAccessor {

  @Input('placeholder') placeholder: String;
  @Input('label') label: String;
  @Input('width') width: number;
  @Input('required') required: boolean;
  @Input('full') full: boolean = false;
  @Output() valueChange = new EventEmitter<T>();
  @Output() onValueChange = new EventEmitter<T>();
  selectedValue: T;
  selectedTooltip: String;

  onChange: (_: any) => void;

  _entries: EntrySelector<T>[];

  limit = 50;
  offset = 0;
  options = new BehaviorSubject<EntrySelector<T>[]>([]);
  options$: Observable<EntrySelector<T>[]>;

  @Input()
  get entries() {
    return this._entries;
  }

  set entries(_entries: EntrySelector<T>[]) {
    this._entries = _entries;
    if(_entries==undefined) return;
    if(this._entries.length>5) {
      document.documentElement.style.setProperty('--small-offset', '-200px');
      document.documentElement.style.setProperty('--large-offset', '248px');
    } else {
      document.documentElement.style.setProperty('--small-offset', '0px');
      document.documentElement.style.setProperty('--large-offset', '0px');
    }
    console.log('set entries:', _entries);
    this.reset();
    this.getNextBatch();
  }


  constructor() { 
    this.reset();
  }

  reset() {
    this.offset = 0;
    this.options$ = this.options.asObservable().pipe(
      scan((acc, curr) => {
          return [...acc, ...curr];
      }, [])
  );
  }

  ngOnInit() {
  }

  get value() {
    return this.selectedValue;
  }

  @Input('value')
  set value(val) {  
    console.log('set value:', val);
    let found=false;
    if(this.entries!=undefined) {      
      for(let i=0;i<this.entries.length && !found;i++) {
        if(this.entries[i].value == val) {
          found=true;
        }
      }
    
      if(!found) {
        console.log(this.entries);
        for(let i=0;i<this.entries.length && !found;i++) {
          /*
          console.log('entry name:', JSON.stringify(this.entries[i].name));
          console.log('entry value:', JSON.stringify(this.entries[i].value), typeof(this.entries[i].value));
          console.log('entry tooltip:', JSON.stringify(this.entries[i].tooltip));

          console.log('search2:', JSON.stringify(this.entries[i].name), JSON.stringify(val));
          console.log('search3:', typeof (this.entries[i]), JSON.stringify(val));
          console.log('search4:', typeof (val));
          */
          if(JSON.stringify(this.entries[i].value) == JSON.stringify(val)) {
            found=true;
            val = this.entries[i].value;
          }
          if(JSON.stringify(this.entries[i].label) == JSON.stringify(val)) {
            found=true;
            val = this.entries[i].value;
          }
        }
      }    
    }
    this.selectedValue = val;
    this.valueChange.emit(this.selectedValue);
    this.onValueChange.emit(this.selectedValue);
    if(this.onChange) this.onChange(this.selectedValue);
  }

  onSelect(value) {
    for(let i=0;i<this.entries.length;i++) {
      if(this.entries[i].value == value) {
        this.selectedTooltip = this.entries[i].tooltip;
        break;
      }
    }
  }
  
  compareFn(c1: T, c2: T): boolean {
    return JSON.stringify(c1) == JSON.stringify(c2);
  }

  writeValue(val: any): void {
   // this.value = val;
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
    this.onChange(this.selectedValue);
  }

  registerOnTouched(fn: any): void {
  }
  
  getNextBatch() {
    console.log('getNextBatch');
    if(!this._entries) return;
    const result = this._entries.slice(this.offset, this.offset + this.limit);
    this.options.next(result);
    this.offset += this.limit;
    console.log('getNextBatch end: ', result);
  }
}

export class EntrySelector<T> {  
  name: String;
  value: T;
  label: String;
  tooltip: String;

  constructor(value: T, label: String, name: String, tooltip: String = null) { 
    this.value = value;
    this.name = name;
    this.label = label;
    this.tooltip = tooltip;
  }
}
